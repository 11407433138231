import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Button from 'react-bootstrap/Button';

import img1 from '../assets/images/tools-main.png';
import img2 from '../assets/images/tools-log-analysis.png';

function AppSoftware() {
  const html = 80;
  const responsive = 95;
  const photoshop = 60;

  return (
    <section id="software" className="block software-block">
      <Container fluid>
        <div className="title-holder">
          <h2>上位机</h2>
          <div className="subtitle">Configuration Tools</div>
        </div>
        <Button variant="outline-danger" className="mb-3" href="https://www.123865.com/s/8OmWTd-Wfgo3" target="_blank">
          下载上位机软件与USB驱动
        </Button>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>Beichu Imu Tools是IMU300系列产品的配套上位机，强大的用户友好GUI可以方便地对产品进行配置、传感器校准、数据曲线显示、3D姿态显示、数据记录、数据分析、日志动态回放等操作</p>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col sm={6}>
            <Image src={img2} />
          </Col>
          <Col sm={6}>
            <p>使用上位机对IMU内部存储的传感数据日志进行曲线展示和分析，此功能对于研发阶段的数据跟踪、问题溯源等具有非常强的实用价值。另外，上位机自带FFT功能，为IMU低通滤波器和陷波滤波器提供设置依据，也为系统的震动分析、模态分析提供了分析手段，具有极高的实用价值。</p>
          </Col>

        </Row>
      </Container>
    </section>
  );
}

export default AppSoftware;