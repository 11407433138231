import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function AppHeader() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">BeiChu 载沧惯导</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#home">Home</Nav.Link>  这里的#id和html中引用元素的id一个道理*/}
            <Nav.Link href="#about">产品简介</Nav.Link>
            <Nav.Link href="#features">技术亮点</Nav.Link>
            <Nav.Link href="#manual">技术规范</Nav.Link>
            <Nav.Link href="#software">上位机</Nav.Link>
            <Nav.Link href="#codes">代码例程</Nav.Link>
            <Nav.Link href="#purchase">购买</Nav.Link>
            {/* <Nav.Link href="#testimonials">其他产品</Nav.Link> */}
            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            {/* <Nav.Link href="#blog">Blog</Nav.Link> */}
            <Nav.Link href="#contact">联系我们</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;