import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-clone',
    title: '行业沉淀',
    // description: '丰富的行业经验，多年的软件沉淀，鲁棒的软件设计，长期运行高可靠高可用。'
    description: '凭借深厚的行业积累和多年的软件开发经验，我们致力于打造鲁棒的软件架构。这些软件系统经过精心设计，以确保在长期运行中维持高可靠性和高可用性，即使在复杂多变的生产环境中也能稳定运行。满足严苛的工业应用需求，保障设备的持续稳定运行和数据的精准传输。'
  },
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: '工业级MEMS传感器',
    description: 'IMU-300使用的MEMS器件具有极高的精度和稳定性。陀螺仪部分采用先进的硅微加工工艺，实现了超低噪声和零偏稳定性，能够检测极其细微的角速度变化，最高可达±2000°/s的全量程范围，这意味着它能精确捕捉并追踪设备的旋转动态。而其内置的三轴加速度计则具有高达±16g的加速度测量范围，有助于准确判断物体的线性加速度和姿态变化。'
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: '消息字段定制',
    description: '使用配套的上位机，可以对传感器的数据输出进行非常精细的定制，可完全输出所有数据，也可仅仅输出用户感兴趣的数据，以节省带宽。最高支持400Hz的数据输出速度。'
  },
  {
    id: 4,
    icon: 'fas fa-desktop',
    title: '数据实时存储',
    description: 'IMU-300全系列产品内置大容量存储卡，支持传感数据的高速存储，最长可不间断存储15天传感器数据，此功能对于研发阶段的数据跟踪、问题溯源等具有非常强的实用价值。最高支持2KHz（2000Hz）的速度进行高速数据存储，高速的数据记录可用于系统的FFT分析，进而为IMU的低通滤波器和陷波滤波器提供设置依据，也为系统的震动分析、模态分析提供丰富的实验数据，具有极高的实验价值和实用价值。'
  },
  {
    id: 5,
    icon: 'fas fa-trophy',
    title: '滤波器设置',
    // description: '支持陀螺仪传感数据低通滤波器、陷波滤波器用户设置，使用配套的上位机，依据对日志的FFT分析结果，对IMU-300系列产品进行滤波器设置，优化输出，降低有害噪声，提升您系统的控制品质。'
    description: '高级的滤波器设置，包括低通滤波器和陷波滤波器，以优化陀螺仪传感数据的处理。通过使用配套的上位机软件，您可以依据对传感器日志进行的FFT分析结果，精确调整滤波器设置。这有利于准确剔除有害噪声，净化数据输出，从而显著提升您系统的控制精度和整体性能。通过这种高级的滤波优化，确保您的系统能够在一个更加稳定和可靠的环境中发挥其最大潜能，为您的工业应用带来卓越的用户体验。'
  },
  {
    id: 6,
    icon: 'fas fa-life-ring',
    title: '多种常用接口',
    // description: '支持UART（TTL）接口、232接口、485接口、USB接口数据通信，几乎覆盖了常规工业场景下的所有接口类型。'
    description: 'IMU-300系列产品支持多种数据通信接口，包括UART、RS-232、RS-485以及USB接口，实现了对工业领域内主流接口类型的广泛支持。全方位的接口支持，确保在不同工业场景下的灵活应用，满足了广大用户对于设备互联和便捷集成的多样化需求。'
  }
]

function AppServices() {
  return (
    <section id="features" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>技术亮点</h2>
          <div className="subtitle">Features</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;