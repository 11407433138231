// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import ListGroup from 'react-bootstrap/ListGroup';

// const pricingData = [
//   {
//     id: 1,
//     plan: 'Basic',
//     price: '£49',
//     features: ['Wireframing', 'Visual Design', '5 pages', 'Free Hosting', 'Support & Assistance'],
//     link: 'https://www.google.com'
//   },
//   {
//     id: 2,
//     plan: 'Premium',
//     price: '£149',
//     features: ['Wireframing', 'Visual Design', '15 pages', 'Free Hosting', 'Support & Assistance'],
//     link: 'https://www.facebook.com'
//   },
//   {
//     id: 3,
//     plan: 'Ultimate',
//     price: '£349',
//     features: ['Wireframing', 'Visual Design', 'Unlimited pages', 'Free Hosting', 'Support & Assistance'],
//     link: 'https://www.twitter.com'
//   }
// ]

// function AppPricing() {
//   return (
//     <section id="pricing" className="block pricing-block">
//       <Container fluid>
//         <div className="title-holder">
//           <h2>Pricing &amp; plans</h2>
//           <div className="subtitle">check our pricing &amp; plans</div>
//         </div>
//         <Row>
//           {
//             pricingData.map(pricing => {
//               return (
//                 <Col sm={4} key={pricing.id}>
//                   <div className='heading'>
//                     <h3>{pricing.plan}</h3>
//                     <span className='price'>{pricing.price}</span>
//                   </div>
//                   <div className='content'>
//                     <ListGroup>
//                       {
//                         pricing.features.map((feature, index) => {
//                           return (
//                             <ListGroup.Item key={index}>{feature}</ListGroup.Item>    
//                           );
//                         })
//                       }
//                     </ListGroup>
//                   </div>
//                   <div className='btn-holder'>
//                     <a href={pricing.link} className='btn btn-primary'>Order Now</a>
//                   </div>
//                 </Col>
//               )
//             })
//           }
//         </Row>
//       </Container>
//     </section>
//   )
// }

// export default AppPricing;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const specifications = [
  {
    title: 'Sensor fusion performance',
    items: [
      { label: '俯仰/横滚精度', value: '0.1 度' },
      { label: '航向精度', value: '1.5 度' },
      { label: '气压高相对精度', value: '0.1 米' },
    ],
  },
  {
    title: 'Sensing components',
    items: [
      { label: '陀螺仪', value: '' },
      { label: '量程', value: '±2000 °/s' },
      { label: '零输出温度特性', value: '0.005 °/s/℃' },
      { label: '噪声密度', value: '0.0028 °/s/√Hz' },
      { label: '加速度计', value: '' },
      { label: '量程', value: '±16 g' },
      { label: '零输出温度特性', value: '0.15 mg/℃' },
      { label: '噪声密度', value: '65 μg/√Hz' },
      { label: '磁罗盘', value: '' },
      { label: '量程', value: '±8 Gauss' },
      { label: '交轴灵敏度', value: '0.1 %' },
      { label: '带宽', value: '200 Hz' },
    ],
  },
  {
    title: 'System specifications',
    items: [
      { label: '机械参数', value: '' },
      { label: 'IP防护等级', value: 'IP00' },
      { label: '尺寸', value: '28 x 28 x 15.5 mm' },
      { label: '重量', value: '12 g' },
      { label: '电气参数', value: '' },
      { label: '输入电压', value: '4.9 - 5.4 V' },
      { label: '功耗（典型）', value: '<250 mW @ 5 V' },
      { label: '接口支持', value: 'UART,USB,232,485' },
      { label: '通信协议', value: 'IMU300' },
      { label: '输出帧率', value: '最高 400 Hz' },
    ],
  },
];

function AppPricing() {
  return (
    <section id="manual" className="block pricing2-block">
      <Container fluid>
        <div className="title-holder">
          <h2>技术规范</h2>
          <div className="subtitle">Specifications</div>
        </div>
        <Button variant="outline-danger" className="mb-3" href="https://www.123865.com/s/8OmWTd-2fgo3" target="_blank">
          下载完整的技术规范书
        </Button>
        <Row>
          {specifications.map((spec, index) => (
            <Col sm={4} key={index}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title className="card-title-custom">{spec.title}</Card.Title>
                  <div className='content'>
                    <ul className="list-unstyled">
                      {spec.items.map((item, idx) => (
                        <li key={idx} style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <strong>{item.label}:</strong> <span>{item.value}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default AppPricing;