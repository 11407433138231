import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    link: 'https://www.123865.com/s/8OmWTd-Cfgo3',
    image: require('../assets/images/code/python_logo2-ps.png'),
    title: 'Python代码例程',
    subtitle: '实时数据采集、解析、动态曲线显示'
  },
  {
    id: 2,
    link: 'https://www.123865.com/s/8OmWTd-Cfgo3',
    image: require('../assets/images/code/MATLAB-logo-ps.png'),
    title: 'Matlab代码例程',
    subtitle: '实时数据采集、解析、动态曲线显示'
  },
  {
    id: 3,
    link: 'https://www.123865.com/s/8OmWTd-Cfgo3',
    image: require('../assets/images/code/STM32_logo2-ps.png'),
    title: 'STM32代码例程',
    subtitle: '实时数据采集、解析、姿态数据串口输出'
  },
  {
    id: 4,
    link: 'https://www.123865.com/s/8OmWTd-Cfgo3',
    image: require('../assets/images/code/Ros_logo.svg-ps.png'),
    title: 'ROS代码例程',
    subtitle: '实现ROS的IMU节点Topic'
  },
  {
    id: 5,
    link: 'https://www.123865.com/s/8OmWTd-Cfgo3',
    image: require('../assets/images/code/arduino_Logo-ps.png'),
    title: 'Arduino代码例程',
    subtitle: 'Arduino采集IMU数据、实时解析并输出姿态数据到串口'
  },
  
  // {
  //   id: 7,
  //   link: 'https://www.google.com',
  //   image: require('../assets/images/img8.jpg'),
  //   title: 'Lonely Path',
  //   subtitle: 'Branding'
  // },
  // {
  //   id: 8,
  //   link: 'https://www.google.com',
  //   image: require('../assets/images/img9.jpg'),
  //   title: 'Appreciation',
  //   subtitle: 'Photography'
  // },
  // {
  //   id: 9,
  //   link: 'https://www.google.com',
  //   image: require('../assets/images/img2.jpg'),
  //   title: 'Happy Days',
  //   subtitle: 'Web Design'
  // }
]

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

function AppWorks() {
  return (
    <section id="codes" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>代码例程</h2>
          <div className="subtitle">Code examples</div>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <div className='portfolio-wrapper'>
                    <a href={works.link} target="_blank" rel="noopener noreferrer">
                      <Image src={works.image} />
                      <div className='label text-center'>
                        <h3>{works.title}</h3>
                        <p>{works.subtitle}</p>
                      </div>
                    </a>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>  
    </section>
  );
}

export default AppWorks;