import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const teamsData = [
  {
    id: 1,
    image: require('../assets/images/product_img/01_imu300ttl.png'),
    fbLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    twitterLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    linkedinLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    name: 'IMU-300-UART',
    designation: '有库存',
    description: 'IMU-300-TTL，9轴/10轴产品，UART接口，最高400Hz输出姿态数据，支持4GB内部存储'
  },
  {
    id: 2,
    image: require('../assets/images/product_img/01_imu300ttl.png'),
    fbLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    twitterLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    linkedinLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    name: 'IMU-300-USB',
    designation: '无库存',
    description: 'IMU-300-USB，9轴/10轴产品，USB接口，支持4GB内部存储'
  },
  {
    id: 3,
    image: require('../assets/images/product_img/01_imu300ttl.png'),
    fbLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    twitterLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    linkedinLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    name: 'IMU-300-232',
    designation: '无库存',
    description: 'IMU-300-USB，9轴/10轴产品，232接口，支持4GB内部存储'
  },
  {
    id: 4,
    image: require('../assets/images/product_img/01_imu300ttl.png'),
    fbLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    twitterLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    linkedinLink: 'https://item.taobao.com/item.htm?ft=t&id=850439364817',
    name: 'IMU-300-485',
    designation: '无库存',
    description: 'IMU-300-485，9轴/10轴产品，485接口，支持4GB内部存储'
  },
  // {
  //   id: 5,
  //   image: require('../assets/images/team5.jpg'),
  //   fbLink: 'https://www.facebook.com',
  //   twitterLink: 'https://www.twitter.com',
  //   linkedinLink: 'https://www.linkedin.com',
  //   name: 'Sophia Pitt',
  //   designation: 'Developer',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui facilis, totam maiores.'
  // },
  // {
  //   id: 6,
  //   image: require('../assets/images/team6.jpg'),
  //   fbLink: 'https://www.facebook.com',
  //   twitterLink: 'https://www.twitter.com',
  //   linkedinLink: 'https://www.linkedin.com',
  //   name: 'Taylor Lopez',
  //   designation: 'Developer',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui facilis, totam maiores.'
  // },
  // {
  //   id: 7,
  //   image: require('../assets/images/team7.jpg'),
  //   fbLink: 'https://www.facebook.com',
  //   twitterLink: 'https://www.twitter.com',
  //   linkedinLink: 'https://www.linkedin.com',
  //   name: 'Ryan Giggs',
  //   designation: 'Content Writer',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui facilis, totam maiores.'
  // },
  // {
  //   id: 8,
  //   image: require('../assets/images/team8.jpg'),
  //   fbLink: 'https://www.facebook.com',
  //   twitterLink: 'https://www.twitter.com',
  //   linkedinLink: 'https://www.linkedin.com',
  //   name: 'David Smith',
  //   designation: 'SEO Expert',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui facilis, totam maiores.'
  // }
]

function AppTeams() {
  return (
    <section id="purchase" className="block teams-block">
      <Container fluid>
        <div className="title-holder">
          <h2>购买</h2>
          <div className="subtitle">Purchase</div>
        </div>
        <Row>
          {
            teamsData.map(teams => {
              return (
                <Col sm={3} key={teams.id}>
                  <div className='image'>
                    <Image src={teams.image} />
                    <div className='overlay'>
                      <div className='socials'>
                        <ul>
                          <li><a href={teams.fbLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-amazon"></i></a></li>
                          <li><a href={teams.twitterLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-amazon"></i></a></li>
                          <li><a href={teams.linkedinLink} target="_blank" rel="noopener noreferrer"><i className="fab fa-amazon"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='content'>
                    <h3>{teams.name}</h3>
                    <span className='designation'>{teams.designation}</span>
                    <p>{teams.description}</p>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppTeams;