import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/AboutIMU300.png';

function AppAbout() {
  const html = 80;
  const responsive = 95;
  const photoshop = 60;

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder" style={{ textAlign: 'left' }}>
          <h2>产品简介</h2>
          <div className="subtitle">About IMU300</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>BC-IMU300系列是利用高性能、小体积、工业级MEMS惯性器件感知物体姿态信息的姿态感知系统，它集成了惯性测量单元（IMU）、磁力计、气压计等传感器，搭载扩展卡尔曼融合滤波算法（EKF），可输出经过传感器融合算法计算得到包含有绝对参考的航向角，俯仰角和横滚角。</p>
            <p>支持四元数输出；支持气压高度数据输出；支持输出原始传感器数据和滤波后的传感数据。IMU-300全系列产品内置大容量高速SD存储卡，支持传感数据的高速存储，最长可不间断存储15天（125Hz速度）传感器数据，此功能对于研发阶段的数据跟踪、问题溯源等具有非常强的实用价值。最高支持2KHz（2000Hz）的速度进行高速数据存储，高速的数据记录可用于系统的FFT分析，进而为IMU的低通滤波器和陷波滤波器提供设置依据，也为系统的震动分析、模态分析提供丰富的实验数据，具有极高的实验价值和实用价值。该系列产品目前已广泛应用于动态控制系统、海陆空姿态系统。这种坚固的低成本惯性系统满足严格的环境要求，非常适合种类繁多的地面车辆和各类平台系统的应用，为量产低成本 OEM 应用提供了选择。</p>
            <p>该系列产品可以精确地感知载体的运动信息，非常适合AR/VR、自动导引小车（AGV）、巡检机器人、无人机、自动驾驶车辆以及其他对运动感知有严格要求的应用领域。它可以与GPS/GNSS、激光雷达、视觉等导航方案形成优势互补，增强设备的导航精度，并减小对外界参考物体的依赖。</p>
            {/* <div className='progress-block'>
              <h4>HTML / CSS / Javascript</h4>
              <ProgressBar now={html} label={`${html}%`} />
            </div>
            <div className='progress-block'>
              <h4>responsive</h4>
              <ProgressBar now={responsive} label={`${responsive}%`} />
            </div>
            <div className='progress-block'>
              <h4>Photoshop</h4>
              <ProgressBar now={photoshop} label={`${photoshop}%`} />
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;